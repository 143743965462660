import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider, useParams } from 'react-router-dom';
import NotFound from './NotFound';
import SuccessPage from './SuccessPage';


export default function () {
    const Navigater = () => {
        window.location.replace("https://www.hireright.com/")
        return null
    }
    const AppRoutes = [
        {
            path: "/",
            exact: true,
            element: <Navigater />,
        },
        {
            path: "/bgv",
            element: <NotFound />,
        },
        {
            path: "/success",
            element: <SuccessPage />,
        },
    ];

    useEffect(() => {
        document.addEventListener('contextmenu', event => event.preventDefault());
    }, [])

    const router = createBrowserRouter(AppRoutes)
    return <RouterProvider router={router} />
}
