import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook for navigation
import LogoSVG from "./logo.svg";
import LogoWhiteSVG from "./logo-white.svg";

const NotFound = () => {
  const navigate = useNavigate(); // Initialize useHistory hook
  const [isGenerating, setIsGenerating] = useState(false); // State to control generator status
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isGenerating) {
      const timeout = setTimeout(() => {
        navigate('/success'); // Navigate to '/success' after 3 seconds
        setIsGenerating(false); // Reset generator status after 3 seconds
      }, 3000);

      return () => clearTimeout(timeout); // Cleanup function
    }
  }, [isGenerating]);

  const handleRegenerate = () => {
    setIsGenerating(true); // Set generator status to true when button is clicked
  };

  return (
    <>
      <nav className="w-full px-4 sm:px-[2.5rem] py-4 shadow">
        <a href="/">
          <img src={LogoSVG} alt="Logo" className="w-40" />
        </a>
      </nav>
      <main className="flex flex-col items-center justify-center gap-20 flex-1">
        <div className="w-3/4 mx-auto">
          <h1 className="text-[1.75rem] font-bold text-center">Oops!!</h1>
          <h3 className="text-center">
            The link has expired. Please click on the REGENERATE button to create a new link.
          </h3>
        </div>
        <button
          className="btn btn--primary btn--hover-red-dark btn-active-red-darker py-2 px-4 rounded"
          onClick={handleRegenerate}
          disabled={isGenerating} // Disable button when generating
        >
          {isGenerating ? (
            <div className="flex items-center justify-center">
              <span className="mr-2">Generating...</span>
              <div className="loader-spinner"></div>
            </div>
          ) : (
            'REGENERATE LINK'
          )}
        </button>
      </main>
      <footer className="bg-black w-full px-4 sm:px-[2.5rem] py-8 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between items-center sm:items-[unset]">
        <a href="/">
          <img src={LogoWhiteSVG} alt="Logo" className="w-40" />
        </a>
        <span className="block md:inline-flex text-white text-center sm:text-left">Copyright © 2005-{currentYear} HireRight, LLC. All Rights Reserved</span>
      </footer>
    </>
  );
};

export default NotFound;