import React from 'react';
import LogoSVG from "./logo.svg"
import LogoWhiteSVG from "./logo-white.svg"


const LandingPage = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <nav className="w-full px-4 sm:px-[2.5rem] py-4 shadow">
                <a href="/">
                    <img src={LogoSVG} alt="Logo" className="w-40" />
                </a>
            </nav>
            <main className="flex flex-col items-center justify-center gap-20 flex-1">
                <div className="w-3/4 mx-auto">
                    <h1 className="text-[1.75rem] font-bold text-center">Success!!</h1>
                    <h3 className="text-center">
                        We have received your request, and once it's processed, you will receive an email notification at your registered email address.
                    </h3>
                </div>
            </main>
            <footer className="bg-black w-full px-4 sm:px-[2.5rem] py-8 flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between items-center sm:items-[unset]">
                <a href="/">
                    <img src={LogoWhiteSVG} alt="Logo" className="w-40" />
                </a>
                <span className="block md:inline-flex text-white text-center sm:text-left">Copyright © 2005-{currentYear} HireRight, LLC. All Rights Reserved</span>
            </footer>
        </>
    );
};

export default LandingPage;
